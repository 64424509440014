import React, { useEffect } from 'react'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormLabel from '@mui/material/FormLabel'
import TextField from '@mui/material/TextField'
import Select from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import { maskCurrency } from '../utils/masks'

const CreditoForm = ({ register, errors, watch, setValue }) => {
  //Campo Select Número de Parcelas
  const [nmParcelas, setNmParcelas] = React.useState('')
  const handleNmParcelas = (event) => {
    setNmParcelas(event.target.value)
  }

  //Campo Select Carência
  const [carencia, setCarencia] = React.useState('')
  const handleCarencia = (event) => {
    setCarencia(event.target.value)
  }

  //Somente Numeros
  const numeros = (evt) => {
    var theEvent = evt || window.event
    var key = theEvent.keyCode || theEvent.which
    key = String.fromCharCode(key)
    //var regex = /^[0-9.,]+$/;
    var regex = /^[0-9.,-/]+$/
    if (!regex.test(key)) {
      theEvent.returnValue = false
      if (theEvent.preventDefault) theEvent.preventDefault()
    }
  }

  //Mascaras de inputs
  const currencyValue = watch('ValorCreditoPretendido')
  useEffect(() => {
    setValue('ValorCreditoPretendido', maskCurrency(currencyValue))
  }, [currencyValue])

  //validação de preenchimento
  const obrigatorio = { required: true }

  return (
    <div className="creditoForm">
      <div className="grid">
        {/*<TextField {...register("valorCreditoPretendido", {required: true, pattern: {
                    value: /^(\d+(?:[\.\,]\d{1,2})?)$/,
                    message: 'Deu erro'
                }})} helperText={errors?.valorCreditoPretendido?.message} error={!!errors.valorCreditoPretendido} label="Valor de Crédito Pretendido" variant="filled" className='valorCredito campoStyle' />*/}

        <div className="valorCredito">
          <TextField
            {...register('ValorCreditoPretendido', obrigatorio)}
            label="Valor de Crédito Pretendido"
            variant="filled"
            className="campoStyle"
          />
          {errors.ValorCreditoPretendido && (
            <span className="erroForm">&gt; Obrigatório</span>
          )}
        </div>

        <Box sx={{ maxWidth: 540 }} className="nmParcelas">
          <FormControl variant="filled" fullWidth className="campoStyle">
            <InputLabel>Número de Parcelas</InputLabel>
            <Select
              {...register('NumeroDeParcelas', obrigatorio)}
              value={nmParcelas}
              label="NmParcelas"
              onChange={handleNmParcelas}
            >
              <MenuItem value={'34x'}>34x</MenuItem>
            </Select>
          </FormControl>
          {errors.NumeroDeParcelas && (
            <span className="erroForm">&gt; Obrigatório</span>
          )}
        </Box>

        <Box sx={{ maxWidth: 540 }} className="carencia">
          <FormControl variant="filled" fullWidth className="campoStyle">
            <InputLabel>Carência</InputLabel>
            <Select
              {...register('Carencia', obrigatorio)}
              value={carencia}
              label="Carencia"
              onChange={handleCarencia}
            >
              <MenuItem value={'2 meses'}>2 meses</MenuItem>
            </Select>
          </FormControl>
          {errors.Carencia && (
            <span className="erroForm">&gt; Obrigatório</span>
          )}
        </Box>

        <div className="creditoEmprego">
          <div className="fCredito">
            <FormControl className="finalidadeCredito radioStyle">
              <FormLabel>Qual é a finalidade do crédito:</FormLabel>
              <RadioGroup className="finalidadeCreditoRadio">
                <FormControlLabel
                  {...register('FinalidadeDoCredito', { required: true })}
                  value="0"
                  control={<Radio />}
                  label="Capital de Giro (compra de estoque, pagamento de fornecedores, etc.)"
                />
                <FormControlLabel
                  {...register('FinalidadeDoCredito', { required: true })}
                  value="15"
                  control={<Radio />}
                  label="Pagamento 13º salário"
                />
                <FormControlLabel
                  {...register('FinalidadeDoCredito', { required: true })}
                  value="1"
                  control={<Radio />}
                  label="Investimento (veículos, máquinas, equipamentos, ampliações, reformas, etc.)"
                />
                <FormControlLabel
                  {...register('FinalidadeDoCredito', { required: true })}
                  value="16"
                  control={<Radio />}
                  label="Marketing"
                />
                <FormControlLabel
                  {...register('FinalidadeDoCredito', { required: true })}
                  value="17"
                  control={<Radio />}
                  label="Tecnologia (computadores, sistemas, etc.)"
                />
              </RadioGroup>
            </FormControl>
            {errors.FinalidadeDoCredito && (
              <span className="erroForm">&gt; Obrigatório</span>
            )}
          </div>

          <div className="fEmpregos">
            <div className="quantosEmpregados">
              <TextField
                {...register('NumeroAtualFuncionario', obrigatorio)}
                label="Quantos empregados a empresa possui?"
                variant="filled"
                className="campoStyle"
                onKeyPress={numeros}
              />
              {errors.NumeroAtualFuncionario && (
                <span className="erroForm">&gt; Obrigatório</span>
              )}
            </div>

            <div className="quantosEmpregos">
              <TextField
                {...register('ProvaveisEmpregos', obrigatorio)}
                label="Serão gerados novos empregos? Quantos?"
                variant="filled"
                className="campoStyle"
                onKeyPress={numeros}
              />
              {errors.ProvaveisEmpregos && (
                <span className="erroForm">&gt; Obrigatório</span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreditoForm
