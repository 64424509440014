import React from 'react'
import logoCrediSapi from '../assets/imgs/logocs.png'
import logosB from '../assets/imgs/logosb.png'

const BcxSectionA = () => {
  return (
    <section id="SectionA">
      <div className="container">
        <div className="text">
          <p>
            O CrediSapi é uma solução para auxiliar prestadores de serviços e
            micro e pequenos empresários a transformarem os seus negócios.
          </p>
          <p>
            O objetivo é facilitar o acesso ao crédito, possibilitando
            investimentos em infraestrutura, planejamento de ampliação,
            melhorias de gestão e outras necessidades da empresa.
          </p>
          <p>
            Essa é uma união de esforços entre a Prefeitura de Sapiranga,
            Sicredi Caminho das Águas, Sebrae e RSGaranti para fortalecer a
            economia local!
          </p>
        </div>

        <div className="logos">
          <img
            className="credCaxias"
            src={logoCrediSapi}
            alt="Logotipo da CrediSapi"
          />
          <img
            className="parceiros"
            src={logosB}
            alt="Logotipos Sicredi, Sebrae, RS Garanti, Sala do Empreendedor, Prefeitura de Sapiranga"
          />
        </div>
      </div>
    </section>
  )
}

export default BcxSectionA
