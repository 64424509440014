import React from 'react'
import Imagem from '../assets/imgs/documentacao.png'

const BcxSectionF = () => {
  function getDeclaratoryYear(){
    return (new Date().getFullYear() - 1)
  };
  return (
    <section id="sectionF">
      <div className="title">
        <h2>
          <strong>Documentação</strong>
          <br />
          necessária
        </h2>
      </div>
      <div className="containerF">
        <div className="texto">
          <p>
            • RG, CPF e comprovante de endereço e renda dos sócios. Pessoas
            casadas ou com união estável devem apresentar também documentação do
            cônjuge. Cópias devem ser coloridas.
          </p>
          <p>
            • Inscrição municipal.
            <a
              href={'https://sapiranga.atende.net/autoatendimento'}
              target={'_blank'}
              rel="noreferrer"
            >
              ACESSE
            </a>
          </p>
          <p>• Mais documentos abaixo, conforme tipo de empresa:</p>
          <p className="tipoEmpresa">
            Se empresa enquadrada como MEI:
            <ul>
              <li>
                - CCMEI: Certificado de Condição de Microempreendedor Individual
              </li>
              <li>- DASN SIMEI: Declaração Anual do SIMEI</li>
            </ul>
          </p>
          <p className="tipoEmpresa">
            Se empresa enquadrada como ME / EPP:
            <ul>
              <li>- Declaração de Firma Individual ou Contrato Social</li>
              <li>
                - Empresa do Simples Nacional: PGDAS-D. Programa Gerador do
                Documento de Arrecadação do Simples Nacional Declaratório {getDeclaratoryYear()},
                com faturamento dos últimos 12 meses
              </li>
              <li>
                - Empresa do Lucro Presumido: SPED Contábil | Recibo Balanço e
                DRE
              </li>
            </ul>
          </p>
          <p className="asterisco">
            * A documentação completa será solicitada somente após análise das
            informações fornecidas no formulário. Preencha com seus dados e
            aguarde o contato do Sicredi.
            <br />* Se você não é associado(a) da Sicredi Caminho das Águas,
            será necessária a abertura de uma conta corrente.
          </p>
        </div>
        <div className="imagem">
          <img src={Imagem} alt="Homem sorrindo segurando um tablet" />
        </div>
      </div>
    </section>
  )
}

export default BcxSectionF
