//MASCARA CNPJ
export const maskCnpj = (value) => {
    if (!value) return '';

    return value.replace(/[\D]/g, '')
        .replace(/(\d{2})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1/$2')
        .replace(/(\d{4})(\d)/, '$1-$2')
        .replace(/(-\d{2})(\d+?)/, '$1')
}

//MASCARA CPF
export const maskCpf = (value) => {
    if (!value) return '';

    return value.replace(/[\D]/g, '')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1-$2')
        .replace(/(-\d{2})(\d+?)/, '$1')
}

//MASCARA DATA
export const maskData = (value) => {
    if (!value) return '';

    return value.replace(/[\D]/g, '')
        .replace(/(\d{2})(\d)/, '$1/$2')
        .replace(/(\d{2})(\d)/, '$1/$2')
        .replace(/(\d{4})(\d+?)/, '$1')
}

//MASCARA TELEFONE FIXO OU CELULAR NO MESMO CAMPO
export const maskPhoneNumber = (value) => {
    if (!value) return '';

    return value.replace(/[\D]/g, '')
        .replace(/(\d{2})(\d)/, '($1) $2')
        .replace(value[5] != 9 ? /(\d{4})(\d)/ : /(\d{5})(\d)/, '$1-$2')
        .replace(/(-\d{4})(\d+?)/, '$1')
}

//MASCARA CEP
export const maskCep = (value) => {
    if (!value) return '';

    return value.replace(/[\D]/g, '')
        .replace(/(\d{5})(\d)/, '$1-$2')
        .replace(/(-\d{3})(\d+?)/, '$1')
}


//MASCARA VALOR MONETÁRIO LIMITADO NO MILHÃO
export const maskCurrency = (value) => {
    if (!value) return '';

    return value.replace(/[\D]/g, '')
        //.replace(/^(\d+)(\d{2})$/, '$1,$2')
        .replace(/^(\d+)(\d{2})/, 'R$ $1,$2')
        .replace(/(\d+)(\d{3})/, '$1.$2')
        .replace(/(\d+)(\d{3})/, '$1.$2')
        .replace(/(\d{3})(\d+?)/, '$1')
}



