import React from 'react'
import Sicredi from '../assets/imgs/logo-1.jpg'
import Sebrae from '../assets/imgs/logo-2.jpg'
import RsGaranti from '../assets/imgs/logo-3.jpg'
import Prefeitura from '../assets/imgs/logo-4.jpg'
import Cresol from '../assets/imgs/logo-5.jpg'

const CcxFooter = () => {
  return (
    <section id="footer">
      <div className="container">
        <div className="logos">
          <a
            href="https://www.sicredi.com.br/coop/caminhodasaguasrs/"
            target={'_blank'}
          >
            <img src={Sicredi} alt="Logo da Sicredi" />
          </a>
          <a href="https://sebraers.com.br" target={'_blank'}>
            <img src={Sebrae} alt="Logo do Sebrae" />
          </a>
          <a href="https://www.rsgaranti.org.br/" target={'_blank'}>
            <img src={RsGaranti} alt="Logo da RS Garanti" />
          </a>
          <a
            href="https://sebraers.com.br/sala_do_empreendedor/sapiranga/"
            target={'_blank'}
          >
            {' '}
            <img src={Cresol} alt="Logo da Dala do Empreendedor" />
          </a>
          <a href="https://sapiranga.atende.net/" target={'_blank'}>
            <img src={Prefeitura} alt="Logo da Prefeitura de Sapiranga" />
          </a>
        </div>
      </div>
    </section>
  )
}

export default CcxFooter
