import React from 'react'
import Pessoa1 from '../assets/imgs/pessoa-1.png'
import Pessoa2 from '../assets/imgs/pessoa-2.png'
import Pessoa3 from '../assets/imgs/pessoa-3.png'
import Aviso from '../assets/imgs/ico-8.png'

const BcxSectionE = () => {
  return (
    <section id="sectionE">
      <div className="container">
        <h2 className="title">
          Quem pode contar <br />
          <strong>com o CrediSapi</strong>
        </h2>

        <div className="micInd rowCard">
          <div className="imagem">
            <img src={Pessoa1} alt="Homem, usando óculos, sorrindo" />
          </div>

          <div className="texto">
            <h3>
              <strong>
                Microempreendedor
                <br />
                Individual
              </strong>
            </h3>
            <p>
              com receita bruta anual igual <br /> ou inferior a R$ 81 mil
            </p>
            <p>
              <strong>Limite de crédito:</strong>
              <br />
              <br />
              R$ 10 mil em 36 parcelas (sem carência) de até R$ 361,22
              <br />
              R$ 10 mil em 34 parcelas (02 meses de carência) de até R$ 389,11
              <br />
              *A taxa de garantia RSGaranti é de até
              <br />
              R$ 662,40
            </p>
            <p></p>
          </div>
        </div>

        <div className="micEmp rowCard">
          <div className="imagem">
            <img src={Pessoa2} alt="Mulher sorrindo, utilizando notebook" />
          </div>

          <div className="texto">
            <h3>
              <strong>Microempresa</strong>
            </h3>
            <p>
              com receita bruta anual igual <br /> ou inferior a R$ 360 mil
            </p>
            <p>
              <strong>Limite de crédito:</strong>
              <br />
              <br />
              R$ 50 mil em 36 parcelas (sem carência) de até R$ 1.806,11
              <br />
              R$ 50 mil em 34 parcelas (02 meses de carência) de até R$ 1.945,56
              <br />
              *A taxa da garantia RSGaranti é de até <br />
              R$ 3.312,00
            </p>
          </div>
        </div>

        <div className="peqEmp rowCard">
          <div className="imagem">
            <img src={Pessoa3} alt="mulher sorrindo" />
          </div>

          <div className="texto">
            <h3>
              <strong>
                Pequenas
                <br />
                Empresas
              </strong>
            </h3>
            <p>
              Faturamento anual entre <br /> R$ 360 mil e R$ 4,8 milhões
            </p>
            <p>
              <strong>Limite de crédito:</strong>
              <br />
              <br />
              R$ 100 mil em 36 parcelas (sem carência) de até R$ 3.612,21
              <br />
              R$ 100 mil em 34 parcelas (02 meses de carência) de até R$
              3.891,13
              <br />
              * A taxa da garantia RSGaranti é de até
              <br />
              R$ 6.624,00
            </p>
          </div>
        </div>

        <div className="aviso">
          <div className="imgAviso">
            <img
              src={Aviso}
              alt="Icone, formato Balão, com Exclamação dentro"
            />
          </div>

          <div className="textoAviso">
            <p>
              • Sujeito a análise de crédito. Parcelas simuladas sem IOF.
              <br />• Terão acesso a linha associados que apresentarem no mínimo
              de <strong>01 ano de faturamento completo ,</strong> declarados
              nos documentos indicados e necessários abaixo.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default BcxSectionE
