import React from 'react'
import logoCrediSapi from '../assets/imgs/logo.png'

const AcxHeader = () => {
  return (
    <section id="header">
      <div className="container">
        <img src={logoCrediSapi} alt="Logotipo da CrediSapi" />
        <h1>
          <strong>Solução em crédito</strong> <br />
          <span>para a sua empresa</span>
        </h1>
      </div>
    </section>
  )
}

export default AcxHeader
